<script setup>
const store = useClientState()
const props = defineProps({
    sections: {
        type: Array,
        default: () => [],
    },
})
const blueColors = ['#0047BB', '#011A43', '#6498ED', '#00296C']
onMounted(() => {
    if (props?.sections?.length) {
        store.firstPanelIsBlue.value = blueColors.includes(props.sections[0].color)
    }
})
</script>

<template>
    <component
        :is="flexibleData.typeHandle == 'flexible' ? 'FlexiblePanels' : 'FlexiblePanelsBrands'"
        v-for="(flexibleData, index) in sections"
        :key="'flexible'+index"
        :color="flexibleData.color"
        :is-sticky="flexibleData.isSticky"
        :prev-sticky="sections[index - 1] ? sections[index - 1]?.isSticky : false"
        :blocks="flexibleData.blocks"
        :panel-id="'flexible'+index"
        :padding-top="flexibleData.paddingTop"
        :padding-bottom="flexibleData.paddingBottom"
    />
</template>
